export const THEME_COLORS = {
  LIGHT_GREY: "#f4f3f3",
  DARK_GREY: "#6b6b6b",
  PRIMARY_BLACK: "#333333",
  WHITE: "#FFF",
  LIGHT_PINK: "#F5EBED",
  PRIMARY: "#bfd8d5",
  SECONDARY: "#b1bed5",
  LIGHT_RED: "#F5796D",
  LIGHT_ORANGE: "#FCC777",
  LIGHT_BLUE: "#5C88C5",
  GQL: "#E434AA",
  REACT: "#61DAFB",
  TS: "#3178C6",
  JS: "#F7DF1E",
  JAVA: "#007396",
  MUI: "#0081CB",
  PYTHON: "#3776AB",
  BOOTSTRAP: "#7952B3",
  NEXT: "#000000",
  NODE: "#339933",
  MONGO: "#47A248",
  FIGMA: "#F24E1E",
  GIT: "#F05032",
  VSCODE: "#007ACC",
  NOTION: "#000000",
  ADOBE_XD: "#FF61F6",
};
